/* eslint-disable no-underscore-dangle */
import { FormControl, Grid, Box, IconButton, Alert, Autocomplete } from '@mui/material';
import { selectHotelById, selectHotelusers } from 'modules/hotels/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import TextField from '@mui/material/TextField';
import { Modal } from 'modules/common/components';
import { notificationActions } from 'modules/common/notifications/slice';
import { selectNotification } from 'modules/common/notifications/selectors';
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { hotelActions } from 'modules/hotels/slice';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { Formik } from 'formik';
import { format } from 'date-fns';
import { Typography } from './style';
import PAGINATION from '../../../common/constants/pagination-limits';
import { isEmpty } from '../../../common/helpers/object';

const FormStyles = { borderRadius: 10 };
//
const UserDataDrawerView = ({ show, isShow, userId }) => {
  //
  const dispatch = useDispatch();
  const notification = useSelector(selectNotification);
  const organizationId = useSelector(selectOrganizationId);
  const hotel = useSelector((state) => selectHotelById(state, userId));
  const hotelUsers = useSelector(selectHotelusers);
  //
  const [isUpdate, setUpdate] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
    roomCapacity: '',
    latestBookingDate: '',
    pmsStartDate: '',
    databricksHotelId: '',
    displayName: '',
    timezone: '',
    createdAt: '',
    updatedAt: '',
    chainName: '',
    city: '',
    state: '',
    country: '',
    hotelClassification: '',
    hotelType: '',
    chainId: '',
    hotelCode: '',
    ibe: '',
    interface: '',
    phram: '',
    phrdnName: '',
    pms: '',
    postalCode: '',
    propertyLatitude: '',
    propertyLongitude: '',
    propertyLocation: '',
    localCurrency: ' ',
    users: [],
  });
  const formElement = useRef(null);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
      isShow(false);
    }
  }, [notification]);
  //
  const onSubmitUpdateHotel = async (values) => {
    const valueObj = {
      currencyUpdate: true,
      localCurrency: values?.localCurrency,
    };
    dispatch(hotelActions.updateHotel({ ...valueObj, organizationId, hotelId: values?.id }));
  };
  //
  useEffect(() => {
    if (!isEmpty(hotelUsers)) {
      setInitialValues({
        ...initialValues,
        users: hotelUsers
          ?.filter((filter) => filter?.username !== undefined)
          .map((filter) => filter.username),
      });
    }
  }, [hotelUsers]);
  //
  useEffect(() => {
    if (hotel) {
      setInitialValues({
        id: hotel?.id,
        name: hotel?.name,
        roomCapacity: hotel?.roomCapacity,
        latestBookingDate: hotel?.latestBookingDate
          ? format(new Date(hotel?.latestBookingDate), 'MM-dd-yyyy HH:mm:ss')
          : '',
        pmsStartDate: hotel?.pmsStartDate
          ? format(new Date(hotel?.pmsStartDate), 'MM-dd-yyyy HH:mm:ss')
          : '',
        databricksHotelId: hotel?.databricksHotelId,
        displayName: hotel?.displayName,
        timezone: hotel?.timezone,
        createdAt: hotel?.createdAt
          ? format(new Date(hotel?.createdAt), 'MM-dd-yyyy HH:mm:ss')
          : '',
        updatedAt: hotel?.updatedAt
          ? format(new Date(hotel?.updatedAt), 'MM-dd-yyyy HH:mm:ss')
          : '',
        reportingCurrency: hotel?.reportingCurrency,
        chainName: hotel?.chainName,
        city: hotel?.city,
        state: hotel?.state,
        country: hotel?.country,
        hotelClassification: hotel?.hotelClassification,
        hotelType: hotel?.hotelType,
        chainId: hotel?.chainId,
        hotelCode: hotel?.hotelCode,
        ibe: hotel?.ibe,
        interface: hotel?.interface,
        phram: hotel?.phram,
        phrdnName: hotel?.phrdnName,
        pms: hotel?.pms,
        postalCode: hotel?.postalCode,
        propertyLatitude: hotel?.propertyLatitude,
        propertyLongitude: hotel?.propertyLongitude,
        propertyLocation: hotel?.propertyLocation,
        localCurrency: hotel?.localCurrency ?? ' ',
        users: [],
      });
      const query = `limit=${PAGINATION.USERS_PER_PAGE_COUNT}`;
      dispatch(
        hotelActions.getHotelUserData({
          organizationId,
          hotelId: hotel?.id,
          query,
        })
      );
    }
  }, [hotel]);
  //
  return (
    <Box
      xs={12}
      md={4}
      ml={4}
      mt={2}
      component={Grid}
      container
      item
      display="flex"
      direction="column"
      sx={{
        backgroundColor: 'white',
        flexShrink: 0,
        display: show ? 'flex' : 'none',
      }}
    >
      <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmitUpdateHotel}>
        {({ dirty, handleBlur, handleChange, handleSubmit, handleReset, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container direction="row" justifyContent="space-between" px={2} mb={6}>
              <Grid
                item
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: 250,
                }}
                alignSelf="center"
              >
                <Typography variant="subtitle1" noWrap sx={{ fontWeight: 600 }}>
                  {hotel?.name}
                </Typography>
              </Grid>
              <Grid>
                {/* <Tooltip title="Update">
                  <IconButton
                    disabled={isSubmitting}
                    onClick={() => setUpdate(true)}
                    color="inherit"
                    size="small"
                  >
                    <Save />
                  </IconButton>
                </Tooltip> */}
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => {
                    if (dirty) {
                      handleReset();
                    }
                    isShow(false);
                  }}
                >
                  <X />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={2} maxHeight={570} sx={{ overflow: 'auto' }}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    label="Name"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.displayName}
                    label="Display Name"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="chainId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.chainId}
                    label="Chain Id"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="chainName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.chainName}
                    label="Chain Name"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="hotelClassification"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hotelClassification}
                    label="Hotel Classification"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.roomCapacity}
                    label="Room Capacity"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.databricksHotelId}
                    label="Databricks Hotel Id"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    disabled
                    size="small"
                    name="localCurrency"
                    value={values.localCurrency ?? ' '}
                    label="Local Currency"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />
                </FormControl>
              </Grid>

              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.timezone}
                    label="Timezone"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.latestBookingDate}
                    label="Latest Booking Date"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pmsStartDate}
                    label="PMS Start Date"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="createdAt"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.createdAt}
                    label="Created At"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.updatedAt}
                    label="Updated At"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="currency"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.reportingCurrency}
                    label="Currency"
                    variant="outlined"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    label="City"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="state"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                    label="State"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                    label="Country"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="hotelType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hotelType}
                    label="Hotel Type"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="hotelCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hotelCode}
                    label="Hotel Code"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="ibe"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ibe}
                    label="IBE"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="interface"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.interface}
                    label="Interface"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="phram"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phram}
                    label="PHRAM"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="phrdnName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phrdnName}
                    label="PHRDN Name"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="pms"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pms}
                    label="PMS"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="postalCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postalCode}
                    label="Postal Code"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="propertyLatitude"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.propertyLatitude}
                    label="Property Latitude"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="propertyLongitude"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.propertyLongitude}
                    label="Property Longitude"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <TextField
                    name="propertyLocation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.propertyLocation}
                    label="Property Location"
                    variant="outlined"
                    size="large"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center" px={4} my={2}>
                <FormControl sx={FormStyles} fullWidth>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    options={values.users ?? []}
                    value={values.users}
                    open={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Users"
                        InputProps={{ ...params.InputProps, readOnly: true }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            height: 'auto',
                            overflow: 'true',
                          },
                          '& .MuiChip-deleteIcon': { display: 'none' },
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} style={{ pointerEvents: 'none' }}>
                        {option}
                      </li>
                    )}
                    disableClearable
                    readOnly
                  />
                </FormControl>
              </Grid>
              <Modal
                open={isUpdate}
                handleClose={() => {
                  if (dirty) {
                    handleReset();
                  }
                  setUpdate(false);
                }}
                title="Update Hotel"
                content="Are you sure you want to update the hotel ?"
                handleSuccess={() => {
                  formElement.current?.click();
                  setUpdate(false);
                }}
                closeLabel="Cancel"
                successLabel="Update"
                variant="contained"
                color="primary"
              />
            </Grid>
            <input ref={formElement} style={{ visibility: 'hidden' }} type="submit" />
          </form>
        )}
      </Formik>
    </Box>
  );
};
//
export default UserDataDrawerView;
