/**
 * Visual feature related request structures are defined here
 */
const API = {
  GET_VISUALS: {
    path: '/organizations/:organizationId/visuals?:query',
    method: 'GET',
  },
  POST_VISUALS: {
    path: '/organizations/:organizationId/visuals',
    method: 'POST',
  },
  DELETE_VISUAL: {
    path: '/organizations/:organizationId/visuals/:id',
    method: 'DELETE',
  },
  PATCH_VISUAL: {
    path: '/organizations/:organizationId/visuals/:visualId',
    method: 'PATCH',
  },
  GET_VISUAL_BY_ID: {
    path: '/organizations/:organizationId/visuals/:selectedVisualId',
    method: 'GET',
  },
  GET_REPORTS: {
    path: '/organizations/:organizationId/reports',
    method: 'GET',
  },
  GET_REPORT_PAGES: {
    path: '/organizations/:organizationId/reports/:reportId/pages',
    method: 'GET',
  },
};
//
export default API;
/**
 * Tags used for visual categorization are defined here
 */
export const TAG_LIST = [
  'Summary',
  'Summary-Hotel',
  'Trend-Time',
  'Trend-Segment',
  'Trend-Hotel',
  'Trend-Time-Tabular',
  'Trend-Segment-Tabular',
  'Trend-Hotel-Tabular',
  'Weekly-Trend-Time',
  'Weekly-Trend-Segment',
  'Weekly-Trend-Hotel',
  'Weekly-Trend-Time-Tabular',
  'Weekly-Trend-Segment-Tabular',
  'Weekly-Trend-Hotel-Tabular',
  'Cumulative-Trend-Time',
  'Cumulative-Trend-Segment',
  'Cumulative-Trend-Hotel',
  'Cumulative-Trend-Time-Tabular',
  'Cumulative-Trend-Segment-Tabular',
  'Cumulative-Trend-Hotel-Tabular',
  'Test',
  'Cumulative',
  'Detailed',
  'Web-Click-Cumulative',
  'web-click-weekly',
  'Web-Click',
  'Pace-Time-Widget',
  'Pace-Segment-Widget',
  'Pace-Hotel-Widget',
  'Pace-Time-Widget-Lag-Aligned',
  'Pace-Segment-Widget-Lag-Aligned',
  'Pace-Hotel-Widget-Lag-Aligned',
  'Pace-Time-Widget-Absolute-Cumulative',
  'Pace-Segment-Widget-Absolute-Cumulative',
  'Pace-Hotel-Widget-Absolute-Cumulative',
  'Pace-Time-Widget-Lag-Aligned-Cumulative',
  'Pace-Segment-Widget-Lag-Aligned-Cumulative',
  'Pace-Hotel-Widget-Lag-Aligned-Cumulative',
  'iPrefer',
  'Workbook-Report',
  'Workbook-Report-Hotel',
  'Region',
  'Trend-Time-Tabular-Daily',
  'Trend-Time-Tabular-Weekly-Combined',
  'Trend-Time-Tabular-Cumulative-Combined',
  'Trend-Hotel-Tabular-Combined',
  'Trend-Hotel-Tabular-Weekly-Combined',
  'Trend-Hotel-Tabular-Cumulative-Combined',
  'Trend-Segment-Tabular-Combined',
  'Trend-Segment-Tabular-Weekly-Combined',
  'Trend-Segment-Tabular-Cumulative-Combined',
  'Trend-Time-Percentage',
  'Trend-Time-Percentage-Tabular',
  'Trend-Time-Percentage-Tabular-Combined',
  'Trend-Segment-Percentage',
  'Trend-Segment-Percentage-Tabular',
  'Trend-Segment-Percentage-Tabular-Combined',
  'Trend-Hotel-Percentage',
  'Trend-Hotel-Percentage-Tabular',
  'Trend-Hotel-Percentage-Tabular-Combined',
  'Trend-Time-Segment',
  'Trend-Time-Segment-Tabular',
  'Weekly-Trend-Time-Segment',
  'Weekly-Trend-Time-Segment-Tabular',
  'Cumulative-Trend-Time-Segment',
  'Cumulative-Trend-Time-Segment-Tabular',
  'Trend-Hotel-Forecast-Off',
  'Trend-Hotel-Tabular-Forecast-Off',
  'Weekly-Trend-Hotel-Forecast-Off',
  'Weekly-Trend-Hotel-Tabular-Forecast-Off',
  'Cumulative-Trend-Hotel-Forecast-Off',
  'Cumulative-Trend-Hotel-Tabular-Forecast-Off',
  'Trend-Hotel-Percentage-Forecast-Off',
  'Trend-Hotel-Percentage-Tabular-Forecast-Off',
  'Pace-Hotel-Widget',
  'Pace-Hotel-Widget-Lag-Aligned',
  'Pace-Hotel-Widget-Absolute-Cumulative',
  'Pace-Hotel-Widget-Lag-Aligned-Cumulative',
  'Pace-Time-Tabular-Combined',
  'Pace-Time-Tabular-Lag-Aligned-Combined',
  'Pace-Time-Tabular-Cumulative-Combined',
  'Pace-Time-Tabular-Cumulative-Lag-Aligned-Combined',
  'Trend-Time-Segment-Tabular-Combined',
  'Trend-Time-Segment-Tabular-Weekly-Combined',
  'Trend-Time-Segment-Tabular-Cumulative-Combined',
  'Pace-Hotel-Tabular-Combined',
  'Pace-Hotel-Tabular-Lag-Aligned-Combined',
  'Pace-Hotel-Tabular-Cumulative-Combined',
  'Pace-Hotel-Tabular-Cumulative-Lag-Aligned-Combined',
  'Pace-Segment-Tabular-Combined',
  'Pace-Segment-Tabular-Lag-Aligned-Combined',
  'Pace-Segment-Tabular-Cumulative-Combined',
  'Pace-Segment-Tabular-Cumulative-Lag-Aligned-Combined',
  'Trend-Time-Moving-Average',
  'Trend-Segment-Moving-Average',
  'Trend-Hotel-Moving-Average',
  'Trend-Time-Moving-Average-Tabular',
  'Trend-Segment-Moving-Average-Tabular',
  'Trend-Hotel-Moving-Average-Tabular',
  'Admin-Dashboard-De-aggregate',
  'Admin-Dashboard',
];
